import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const CustomTooltip = ({ children, items = [], title }) => {
  const tip = title || items.join('\n');
  return (
    <div style={{ position: 'relative' }}>
      {children}
      <Tooltip
        style={{
          position: 'absolute',
          right: 10,
          top: -20,
        }}
        placement="bottom-end"
        title={<div style={{ whiteSpace: 'pre-line' }}>{tip}</div>}
      >
        <HelpIcon />
      </Tooltip>
    </div>
  );
};

export default CustomTooltip;
